import axios from "axios";

const endpoint_sqs_report = process.env.REACT_APP_ENDPOINT_ADD_REPORT_SQS;
const apikey_sqs_report = process.env.REACT_APP_APIKEY_ADD_REPORT_SQS;
const endpoint_validate_url_site = process.env.REACT_APP_ENDPOINT_VALIDATE_URL_SITE;

let header = {
  "Content-Type": "application/json",
  "Accept": "*/*",
  "X-Api-Key": `${apikey_sqs_report}`,
};

const sendParameterReportCompany = (data) => {
  let url = `${endpoint_sqs_report}`;

  header = {
    ...header,
  };

 

  return axios.post(url, data, {
    headers: header,
  });
};

const validateUrlSiteCompany = (ordCode,token,email) => {
  let url = `${endpoint_validate_url_site}/${ordCode}/${token}/${email}`;
  header = {
    ...header,
  };
  return axios.get(url,{
    headers: header,
  });
};

const exportedObjectSqsAdd = {
  sendParameterReportCompany,
  validateUrlSiteCompany
};

export default exportedObjectSqsAdd;
