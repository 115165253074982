import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import sqsAddReport from "../services/AddSqsService";
import kentico from "../services/KenticoService.js";
import { updateCulture } from "../store/culture/action";

const KenticoLoaderComponent = (props) => {
  const { country, language, orgcode, token, email } = useParams();
  const [validaUrl, setValidaUrl] = useState("true");

  const validar = useCallback(async () => {
    const result = await sqsAddReport.validateUrlSiteCompany(
      orgcode,
      token,
      email
    );
    setValidaUrl(result.data.status);
  }, []);

  useEffect(() => {
    validar();

    const validateAndSet = async () => {
      const { data: landingData } = await kentico.getItemData(
        "companies_reports",
        `${language}_${country}`
      );

      const publishOptions =
        landingData.items[0].elements.publish_options.value[0];
      const isCultureVisible =
        landingData.modular_content[publishOptions].elements.visible.value[0]
          .codename;

      const { data: headerData } = await kentico.getItemData(
        "header",
        `${language}_${country}`
      );

      const { data: footerData } = await kentico.getItemData(
        "footer",
        `${language}_${country}`
      );

      const { data: stationsData } = await kentico.getItemData(
        "stations",
        `${language}_${country}`
      );

      props.updateCulture({
        country,
        language,
        url: `/${country}/${language}`,
        culture: `${language}_${country}`,
        elements: landingData.items[0].elements,
        modular: landingData.modular_content,
        loader: true,
        headerData,
        footerData,
        stationsData,
        orgcode,
        token,
        email,
        valida: validaUrl,
        isCultureVisible,
      });

      return <div></div>;
    };

    country && language && validateAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, language, validaUrl, validar]);

  return <></>;
};

export default connect(null, { updateCulture })(KenticoLoaderComponent);
