import { useEffect } from "react";
import TagManager from "react-gtm-module";

//Redux
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Header from "./components/Header";
import KenticoLoaderComponent from "./components/KenticoLoaderComponent";
// import sqsAddReport from './services/AddSqsService';

// import Resultados from "./components/Resultados";
import Main from "./components/Main";
import { updateCulture } from "./store/culture/action";
import { getCulture } from "./store/culture/reducer.js";

const App = (props) => {
  const { culture } = props;
  const { isCultureVisible } = culture;

  useEffect(() => {
    // Initialize Google Tag Manager
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM,
    });
  }, []);

  if (!culture.loader) {
    return (
      <Switch>
        <Route
          exact
          path={`/:country/:language/:app/:orgcode/:token/:email`}
          component={KenticoLoaderComponent}
        />
      </Switch>
    );
  }

  const ContentComponent = () => (
    <>
      <Banner />
      <Switch>
        <Route
          exact
          path={`/:country/:lang/${process.env.REACT_APP_ROUTE_NAME}/:orgcode/:token/:email`}
          component={Main}
        />
      </Switch>
    </>
  );

  return (
    <div className="App">
      <Header />

      <div className="rt-widget">
        <div className="loading">
          {"yes" !== isCultureVisible ? null : <ContentComponent />}
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, { updateCulture })(App);
