import parse from 'html-react-parser';


const RRSSLink = ({ href, content }) => {
  return (
    <a href={href}
       className="d-inline-block">{ parse(content)}</a>
  )
}

export default RRSSLink;
