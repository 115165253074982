import { useEffect, useState } from "react";
//Redux
import { connect } from "react-redux";

import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "../assets/css/datepicker.css";
import sqsAddReport from "../services/AddSqsService";
import { getCulture } from "../store/culture/reducer";

registerLocale("es", es);
registerLocale("pt", pt);
registerLocale("en", en);

const Main = (props) => {
  const [messageModal, setMessageModal] = useState("");
  const { elements = props.culture.elements } = props;

  const language = props.culture.language;
  const country = props.culture.country;
  const calendarLanguage = `${language}-${country.toUpperCase()}`;

  const [fechaFin2, setFechaFin2] = useState(
    new Date(moment().add(1, "M").format())
  );

  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [disabledCalendarEnd, setDisabledCalendarEnd] = useState(true);
  const currentDate = new Date();

  const enviarMail = async (e) => {
    let loading = window.newRtLoader(".loading", {
      logo_color: "white", // white or colored
      background_color: "rgba(255,255,255,.97)", // any color in hex or rgb/rgba
      airplane_color: "#163a70", // any color in hex
      dots_color: "#163a70", // any color in hex
    });

    let startDate = moment(e.target.startDate.value, "dd/MM/yyyy").toDate();
    let endDate = moment(e.target.endDate.value, "dd/MM/yyyy").toDate();
    e.preventDefault();

    if (endDate < startDate) {
      setMessageModal(
        elements.modal_validate_error ? elements.modal_validate_error.value : ""
      );
      window.$("#dev_modal").modal("show");
    } else {
      loading.startLoader();
      const data = {
        orgCode: props.culture.orgcode,
        emailAddress: props.culture.email,
        dateInit: e.target.startDate.value,
        dateFinish: e.target.endDate.value,
        culture: props.culture.language,
      };

      return sqsAddReport
        .sendParameterReportCompany(data)
        .then((result) => {
          loading.stopLoader();
          setMessageModal(
            elements.modal_request_sent ? elements.modal_request_sent.value : ""
          );
          window.$("#dev_modal").modal("show");
        })
        .catch((err) => {
          loading.stopLoader();
          // setMessageModal(elements.modal_validate_error ? elements.modal_validate_error.value : '');
          setMessageModal(
            elements.modal_request_sent ? elements.modal_request_sent.value : ""
          );
          window.$("#dev_modal").modal("show");
        });
    }
  };

  useEffect(() => {
    if (props.culture.valida === "false") {
      setMessageModal(
        elements.modal_validate_error ? elements.modal_validate_error.value : ""
      );
      window.$("#dev_modal_valida").modal("show");
    }
  }, [
    props.culture.valida,
    elements.modal_validate_error,
    fechaFin2,
    disabledCalendarEnd,
    endDate,
    startDate,
  ]);

  const formatDate = (date) => {
    const formattedDay = String(date).padStart(2, "0");
    return formattedDay;
  };

  const customHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    // Get the month name (e.g., "July")
    const monthName = date.toLocaleString(calendarLanguage, { month: "long" });

    // Get the year (e.g., "2023")
    const year = date.getFullYear();

    return (
      <div className="react-datepicker__header">
        <button
          disabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}
          type="button"
          className={`react-datepicker__navigation react-datepicker__navigation--previous ${
            prevMonthButtonDisabled
              ? "react-datepicker__navigation--disabled"
              : "react-datepicker__navigation--enabled"
          }`}
          aria-label="Previous Month"
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
            Previous Month
          </span>
        </button>
        <button
          disabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
          type="button"
          className={`react-datepicker__navigation react-datepicker__navigation--next ${
            nextMonthButtonDisabled
              ? "react-datepicker__navigation--disabled"
              : "react-datepicker__navigation--enabled"
          }`}
          aria-label="Next Month"
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
            Next Month
          </span>
        </button>
        <div className="react-datepicker__current-month">
          <span style={{ fontWeight: 700 }}>{monthName}</span> {year}
        </div>
      </div>
    );
  };

  return (
    <div className="container" id="card-container">
      <div className="row mt-5">
        <div className="col-12 text-center">
          <h3 className="text-uppercase font-lato-bold mb-5">
            {elements.instructions_title.value}
          </h3>
          <br />
          <h5>{elements.instructions_subtitle.value}</h5>
          <br />
          <div className="row">
            <div className="col-md-6 col-12 mb-3">
              <div className="row">
                <div className="col-1 px-0">
                  <h1 className="color-light-blue-js text-left">1</h1>
                </div>
                <div className="col-11 step-text text-left">
                  {elements.instruction_a.value}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 mb-3">
              <div className="row">
                <div className="col-1 px-0">
                  <h1 className="color-light-blue-js text-left">2</h1>
                </div>
                <div className="col-11 step-text text-left">
                  {elements.instruction_b.value}
                </div>
              </div>
            </div>
          </div>

          <form
            className="row justify-content-md-center mt-5 pb-5"
            onSubmit={enviarMail}
          >
            <div className="col-md-4 text-center">
              <div className="form-group">
                <label className="form-label">
                  {elements.date_start_placeholder.value}
                </label>
                <DatePicker
                  renderCustomHeader={customHeader}
                  renderDayContents={(day) => formatDate(day)}
                  calendarStartDay={language === "es" ? 1 : 0}
                  locale={language}
                  dayClassName={(date) => {
                    const dayOfWeek = date.getDay();

                    // Verifica si el día es viernes (5) o sábado (6) y si el idioma es inglés
                    if (
                      (dayOfWeek === 5 || dayOfWeek === 6) &&
                      language === "en"
                    ) {
                      return "dg-dp-square dg-dp-bold"; // Clase para texto en negrita
                    } else {
                      if (language === "en") return "dg-dp-square dg-dp-normal";

                      return "dg-dp-square"; // Clase predeterminada
                    }
                  }}
                  formatWeekDay={(day) => day.substring(0, 3).toUpperCase()}
                  showPopperArrow={false}
                  name="startDate"
                  selected={startDate ? startDate : undefined}
                  onChange={(date) => {
                    setStartDate(moment(date, "dd/MM/yyyy").toDate());
                    setEndDate(
                      moment(date).add(1, "M").isSameOrAfter(currentDate)
                        ? currentDate
                        : moment(date).add(1, "M").toDate()
                    );
                    setMaxDate(
                      moment(date).add(1, "M").isSameOrAfter(currentDate)
                        ? currentDate
                        : moment(date).add(1, "M").toDate()
                    );
                  }}
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date(moment().subtract(1, "d").format())}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="form-group">
                <label className="form-label">
                  {elements.date_finish_placeholder.value}
                </label>
                <DatePicker
                  renderCustomHeader={customHeader}
                  renderDayContents={(day) => formatDate(day)}
                  calendarStartDay={language === "es" ? 1 : 0}
                  locale={language}
                  dayClassName={(date) => {
                    const dayOfWeek = date.getDay();

                    // Verifica si el día es viernes (5) o sábado (6) y si el idioma es inglés
                    if (
                      (dayOfWeek === 5 || dayOfWeek === 6) &&
                      language === "en"
                    ) {
                      return "dg-dp-square dg-dp-bold"; // Clase para texto en negrita
                    } else {
                      if (language === "en") return "dg-dp-square dg-dp-normal";

                      return "dg-dp-square"; // Clase predeterminada
                    }
                  }}
                  formatWeekDay={(day) => day.substring(0, 3).toUpperCase()}
                  showPopperArrow={false}
                  name="endDate"
                  selected={endDate ? endDate : undefined}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  maxDate={maxDate}
                  minDate={startDate}
                  disabled={!!!endDate}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>

            <div className="col-8 text-center px-0 mx-auto mt-6">
              <button className="btn btn-red" type="submit">
                {elements.send_button ? elements.send_button.value : ""}
                <i
                  className="fal fa-chevron-circle-right pl-1 pl-sm-2"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </form>
        </div>
        <div id="dev_modal" className="modal modal-select">
          <div className="modal-content">
            <div className="modal-header">
              <img
                className="icon-modal"
                src="https://preview-assets-us-01.kc-usercontent.com:443/b2956330-c34f-0064-2c6f-27bd5c0147fc/bc429bc6-4968-4ed0-acf6-4f67889b9692/exclamation.gif"
                alt=""
              />
              <h3 className="mt-1 d-inline text-white">
                {elements.modal_title.value}
              </h3>
            </div>
            <div className="modal-text">
              <p id="dev_text">{messageModal}</p>
              <button id="modal_btn" className="modalBtn" data-dismiss="modal">
                OK
              </button>
            </div>
          </div>
        </div>
        <div id="dev_modal_valida" className="modal modal-select">
          <div className="modal-content">
            <div className="modal-header">
              <img
                className="icon-modal"
                src="https://preview-assets-us-01.kc-usercontent.com:443/b2956330-c34f-0064-2c6f-27bd5c0147fc/bc429bc6-4968-4ed0-acf6-4f67889b9692/exclamation.gif"
                alt=""
              />
              <h3 className="mt-1 d-inline text-white">
                {elements.modal_title.value}
              </h3>
            </div>
            <div className="modal-text">
              <p id="dev_text">{messageModal}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(Main);
